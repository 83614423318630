.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 10px 0 5px 0;

  label {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    position: relative;

    .copyIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 40px;
      height: 40px;
      background-color: var(--color-secondary-light);
      border-radius: 5px;
      backdrop-filter: blur(10px);
      color: var(--color-font-light);
      transition: 0.3s all ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: var(--color-primary);
        color: var(--color-font);
      }

      &:active {
        transform: scale(0.95);
      }
    }

    input {
      background: var(--color-input-background);
      border: 1px solid var(--color-input-border);
      align-self: stretch;
      border-radius: 6px;
      padding: 15px;
      outline: none;
      color: var(--color-font);
      transition: 0.2s all ease-in-out;

      &::placeholder {
        color: var(--color-font);
        opacity: 0.5;
      }

      &:focus {
        border: 1px solid var(--color-accent);
        box-shadow: 0 5px 10px var(--color-accent-light);
      }
    }
  }
}
